export default {
  name: 'NewsCard',
  props: {
    article: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  components: {},

  created() {},
  mounted() {},
  computed: {},

  methods: {}
};
